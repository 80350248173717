




























































import {
  Component, Prop, Vue, Watch 
} from 'vue-property-decorator';

import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';
import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import Checkbox from '@/components/Input/Checkbox/index.vue';
import ButtonReportProblem from '@/components/ButtonReportProblem/ButtonReportProblem.vue';

import ExerciseInstructionLoading from './ExerciseInstructionLoading.vue';

import ActiveModal from '@/share/Util/ActiveModal';
import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';

import ModalInstrucoes from '../ModalInstrucoes/ModalInstrucoes.vue';

@Component({
  components: {
    ContainerFluid,
    BoxContainer,
    ButtonReportProblem,
    Checkbox,
    ModalInstrucoes,
    ExerciseInstructionLoading
  }
})
export default class ExerciseInstruction extends Vue {
  @Prop() exercise!: Record<string, any>;
  @Prop() formData!: Record<string, any>;
  @Prop() loading!: boolean;

  private listInstruction: Array<string> = [];
  private accepted = false;

  private setModal = new ActiveModal();

  @Watch('loading', {
    immediate: true
  })
  setListInstruction() {
    if (!this.loading) {
      this.listInstruction = [
        `Este exercício é composto por ${this.setQtdQuestions() || '01'} questões;`,
        'Tenha lápis e papel a mão;',
        'Caso tenha algum problema com seu serviço de internet, não se preocupe, seu exercício estará salvo de onde você parou;',
        'Só pode realizar o exercício uma única vez;',
        'Você receberá o resultado ao finalizar, então capricha e bom exercício.'
      ];
    }
  }

  setQtdQuestions() {
    if (this.exercise && this.exercise?.questionCount) {
      return this.exercise.questionCount < 10
        ? `0${this.exercise.questionCount}`
        : `${this.exercise.questionCount}`;
    }

    return '';
  }

  startExercise(): void {
    if (this.accepted) {
      this.setTrackAmplitude();

      this.$store.commit('setExerciseOptions', this.formData);
      const {
        context, path, topicID, fileID, slugFile 
      } = this.$route.params;

      this.$router.push({
        path: `/exercicios/${context}/${path}/${topicID}/${fileID}/${slugFile}/questoes`
      });
    } else {
      this.setModal.activeModal('modalInstrucoes');
    }
  }

  setCheckbox(type: string, checked: boolean) {
    this.accepted = !checked;
  }

  setTrackAmplitude() {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'ExerciseInstruction',
          local: 'Começar o exercício'
        }
      }
    });
  }
}
