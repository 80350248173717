import { render, staticRenderFns } from "./RadioBox.vue?vue&type=template&id=77c8e9c0&scoped=true&"
import script from "./RadioBox.vue?vue&type=script&lang=ts&"
export * from "./RadioBox.vue?vue&type=script&lang=ts&"
import style0 from "./RadioBox.scss?vue&type=style&index=0&id=77c8e9c0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77c8e9c0",
  null
  
)

export default component.exports