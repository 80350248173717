










import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class BoxContainerPersonalize extends Vue {
  @Prop() title!: string;
  @Prop() padding!: string;
  @Prop() titleMargin!: string;
}
